import React from 'react';
import PropTypes from 'prop-types';

import { Title, Text } from '../../elements';
import { Swiper } from 'components';

import { CenteredSliderStyled } from './CenteredSlider.styled';

const centerSliderConfig = {
  className: 'center',
  centerMode: true,
  infinite: true,
  navigation: true,
  pagination: true,
  spaceBetween: 16,
  slidesPerView: 1.2,
  speed: 500,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    480: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    640: {
      slidesPerView: 1,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 8,
      centeredSlides: false,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 16,
    },
  },
};

const CenteredSlider = ({ items, titleSlider, sectionDescription, renderItem }) => {
  return (
    <CenteredSliderStyled>
      <Title as='h3' modifiers='tertiarySmall' content={titleSlider} />
      <Text modifiers='noLineHeight' content={sectionDescription} />
      <Swiper items={items} swiperProps={centerSliderConfig} renderSlideCard={renderItem} />
    </CenteredSliderStyled>
  );
};

CenteredSlider.propTypes = {
  items: PropTypes.array,
  children: PropTypes.node,
  titleSlider: PropTypes.string,
  sectionDescription: PropTypes.string,
  renderItem: PropTypes.func,
};

export default CenteredSlider;
