import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { findIndex as _find, isEqual as _isEqual, isEmpty as _isEmpty } from 'lodash';

import { PillLabel, Media, Swiper } from 'components';
import { FARMSTAND_SKUS } from 'constants/sku';
import { ProductHeroSliderStyled, HeroSliderInner, ProductSlideStyled } from './FarmCarouselMobile.styled';

import FarmVisualizerBanner from '../farm-visualizer-banner/FarmVisualizerBanner';

import { trackClick } from 'utils/googleTagManager';
import growingEnvironments from 'constants/growingEnvironments';

const { OUTDOOR, INDOOR } = growingEnvironments;

const sliderBaseConfig = {
  navigation: false,
  pagination: true,
  speed: 1000,
  slidesPerView: 1,
  slidesToScroll: 1,
  spaceBetween: 12,
};

const FarmCarouselMobile = forwardRef(({ data = [], dataGatsby = [], promo, playVideoComponent, visualizerData, environment }, ref) => {
  const [swiper, setSwiper] = useState(null);
  const finalData = useMemo(() => {
    const imgs = dataGatsby?.length > 0 ? dataGatsby : data;
    return imgs.filter(({ title }) => (!title.includes(INDOOR) && !title.includes(OUTDOOR)) || title.includes(environment));
  }, [data, dataGatsby, environment]);
  const [hasImageSku, setHasImageSku] = useState(!!finalData?.[0]?.description);

  useImperativeHandle(ref, () => ({
    onSelectFarmstand: (sku) => {
      const farmImgIndex = _find(finalData, { description: sku });
      farmImgIndex > -1 && swiper?.slideTo(farmImgIndex);
      trackClick({ action: 'FS Image' });
    },
  }));

  const renderSlide = useCallback((slideItem, index) => {
    return (
      <ProductSlideStyled key={index}>
        <Media altDescription={FARMSTAND_SKUS.includes(slideItem?.description) && slideItem.title} asset={slideItem} nativeWidth={550} />
      </ProductSlideStyled>
    );
  }, []);

  const onSlideIndexChange = ({ activeIndex }) => {
    setHasImageSku(!!finalData?.[activeIndex]?.description);
  };

  const extraProps = {
    onSlideChange: onSlideIndexChange,
    onSwiper: setSwiper,
  };

  return (
    <ProductHeroSliderStyled>
      {!!promo && hasImageSku && <PillLabel content={promo} />}
      <HeroSliderInner>
        <Swiper swiperProps={{ ...sliderBaseConfig, ...extraProps }} renderSlideCard={renderSlide} items={finalData} />
      </HeroSliderInner>
      {!_isEmpty(visualizerData) && (
        <FarmVisualizerBanner title={visualizerData.title} body={visualizerData.body} mobileCtas={visualizerData.additionalButtons} />
      )}
      {playVideoComponent && playVideoComponent()}
    </ProductHeroSliderStyled>
  );
});

FarmCarouselMobile.displayName = 'FarmCarouselMobile';

FarmCarouselMobile.propTypes = {
  data: PropTypes.array,
  dataGatsby: PropTypes.array,
  promo: PropTypes.string,
  playVideoComponent: PropTypes.any,
  visualizerData: PropTypes.object,
  environment: PropTypes.string,
};

const shouldPreventUpdate = (prevProps, nextProps) => {
  return (
    _isEqual(prevProps.data, nextProps.data) &&
    _isEqual(prevProps.dataGatsby, nextProps.dataGatsby) &&
    _isEqual(prevProps.visualizerData, nextProps.visualizerData) &&
    prevProps.promo === nextProps.promo &&
    prevProps.environment === nextProps.environment &&
    !!prevProps.playVideoComponent === !!nextProps.playVideoComponent
  );
};

export default memo(FarmCarouselMobile, shouldPreventUpdate);
