/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { Text, Image, Title, GatsbyImage } from 'elements';
import { ContentWrapper, SliderSectionStyled, SliderItem, ItemInner, SliderWrapper } from './styled';

import { ContentSection, Swiper } from 'components';

import RightArrow from 'elements/icon/assets/right.svg';
import LeftArrow from 'elements/icon/assets/left.svg';

// eslint-disable-next-line react/prop-types, no-unused-vars
const SlickButton = ({ currentSlide, slideCount, children, ...props }) => <div {...props}>{children}</div>;

const TestimonialsListSection = ({ contentBlock, gatsbyImages }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesPerView: 1,
    slidesToScroll: 1,
    navigation: true,
    nextArrow: (
      <SlickButton>
        <RightArrow />
      </SlickButton>
    ),
    prevArrow: (
      <SlickButton>
        <LeftArrow />
      </SlickButton>
    ),
  };

  const testemonials = _get(contentBlock, 'fields.percentages');

  const renderTestimonial = (testimonialList) => {
    const contentId = testimonialList.sys.id;
    const gatsbyImageData = gatsbyImages?.find?.((e) => e?.contentful_id === contentId)?.mediaBackground?.[0]?.gatsbyImageData;
    const mediaBackground = _get(testimonialList, 'fields.mediaBackground[0].fields.file.url');
    const mediaBackgroundAlt = _get(testimonialList, 'fields.mediaBackground[0].fields.description');

    return (
      <SliderItem key={testimonialList.sys.id}>
        <ItemInner>
          <Text content={testimonialList.fields.body} isHTML />
          {!!gatsbyImageData && <GatsbyImage modifiers='relativePosition' alt={mediaBackgroundAlt} image={gatsbyImageData} />}
          {!!mediaBackground && !gatsbyImageData && (
            <Image modifiers='relativePosition' picture={mediaBackground} alt={mediaBackgroundAlt} nativeWidth={565} />
          )}
          <Text content={testimonialList.fields.body2} />
        </ItemInner>
      </SliderItem>
    );
  };
  return <Swiper swiperProps={settings} items={testemonials} renderSlideCard={renderTestimonial} />;
};

TestimonialsListSection.propTypes = {
  contentBlock: PropTypes.object,
  gatsbyImages: PropTypes.array,
  initialSlide: PropTypes.number,
};

TestimonialsListSection.defaultProps = {
  initialSlide: 0,
  gatsbyImages: [],
};

const SliderSection = ({ gatsbyImages, contentBlock, title, body, initialSlide }) => {
  return (
    <SliderSectionStyled>
      <ContentSection>
        <ContentWrapper>
          <Text content={body} />
          <Title content={title} modifiers='secondarySmall' as='h3' />
        </ContentWrapper>
        <SliderWrapper>
          <TestimonialsListSection contentBlock={contentBlock} gatsbyImages={gatsbyImages} initialSlide={initialSlide} />
        </SliderWrapper>
      </ContentSection>
    </SliderSectionStyled>
  );
};

SliderSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  contentBlock: PropTypes.object,
  gatsbyImages: PropTypes.array,
  initialSlide: PropTypes.number,
};

SliderSection.defaultProps = {
  gatsbyImages: [],
};

export default SliderSection;
