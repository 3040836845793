import { put, select, takeLatest, call } from 'redux-saga/effects';
import { navigate } from 'gatsby';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

import { axiosPost, axiosGet } from 'utils/api-utils';
import { environment, authDomain } from 'utils/envConfig';
import { getStorage } from 'utils/storageManager';
import logError from 'utils/errorHandler';
import { trackEnhancedCheckout } from 'utils/googleTagManager';
import { postOrderCleanup, createStripeOrder } from 'app/pages/checkout/CheckoutHelpers';

import { resetReservation } from 'reduxState/reservation/reservation';
import { clearCart, setCartValue, getCart, checkDuplicatedOrderAction, setIsAltDuplicatedOrder } from 'reduxState/cart';
import {
  userLogout,
  setUserValue,
  getLoginState,
  getCAPIUserInfo,
  fetchMyFarm,
  fetchUserDevices,
  getCustomer,
  getUserAuthToken,
} from 'reduxState/user';
import { getCatalogEnvironment } from 'reduxState/catalog';
import paths from 'constants/paths';
import { shopCategories } from 'constants';
import { checkIsDuplicatedOrder } from 'utils/duplicate-order-util';

function* guestAuthToken({ payload }) {
  const auth = getAuth();
  const { values } = payload;
  try {
    const { SimpleCrypto } = yield import('simple-crypto-js');
    const key = SimpleCrypto?.generateRandom?.();
    const password = values.email + key;
    yield put(setUserValue({ label: 'password', value: password }));
    const guestSignupResponse = yield createUserWithEmailAndPassword(auth, values.email, password);
    const authToken = yield guestSignupResponse.user.getIdToken();
    return authToken;
  } catch (error) {
    yield put({ type: 'SUBMIT_ALT_PAYMENT_FAILED' });
  }
}

function* checkAccount({ payload }) {
  const { email } = payload;
  try {
    const hasAccount = yield axiosGet(`/app/public/checkAccount/${email}`, {
      headers: { authDomain },
    });

    if (hasAccount && hasAccount.data) {
      yield put(setUserValue({ label: 'hasAccount', value: true }));
    }
  } catch (error) {
    logError(error);
  }
}

function* checkDuplicatedOrder({ payload: { values } }) {
  const cart = yield select(getCart);
  const isLoggedIn = yield select(getLoginState);
  const userAuthToken = yield select(getUserAuthToken);

  let authToken;
  if (isLoggedIn && userAuthToken) {
    authToken = userAuthToken;
  } else {
    authToken = yield call(guestAuthToken, { payload: { values } });
  }

  const isDuplicate = yield checkIsDuplicatedOrder({ authToken, cart });
  yield put(setIsAltDuplicatedOrder(isDuplicate));
}

function* doLogout() {
  yield put(setCartValue({ label: 'canUpdate', value: true }));
  yield put(setCartValue({ label: 'paymentType', value: 'credit' }));
}

function updateDataLayer({ payload }) {
  const { values, cart } = payload;

  if (environment === 'production') {
    const lgUserId = getStorage('lgClientId');
    window.LogRocket && window.LogRocket.identify(lgUserId, { name: values.firstName + ' ' + values.lastName, email: values.email });
  }

  trackEnhancedCheckout({ actionField: { step: 2, option: cart.paymentType }, products: cart.items });
}

function* submitAltPayment({ payload }) {
  const { e, stripe, values, altPayType } = payload;
  const type = e.methodName;
  const { exp_month, exp_year, last4, brand } = e.paymentMethod.card;

  const cart = yield select((state) => state.cart);
  const user = yield select((state) => state.user);
  const isLoggedIn = yield select(getLoginState);
  const capiData = yield select(getCAPIUserInfo);
  const customer = yield select(getCustomer);
  const catalogEnvironment = yield select(getCatalogEnvironment);
  const reservation = yield select((state) => state.reservation);

  const checkForDuplicateOrder = false;
  const order = createStripeOrder(
    values,
    cart.shouldOverrideAddress,
    null,
    cart,
    user,
    reservation,
    catalogEnvironment,
    checkForDuplicateOrder
  );
  try {
    let authToken;

    if (user.authToken) {
      authToken = user.authToken;
    } else {
      authToken = yield call(guestAuthToken, { payload: { values } });
    }
    yield call(updateDataLayer, { payload: { values, cart } });

    const { data } = yield axiosPost(
      '/app/lgcom/cartCheckoutAltPay',
      { ...order, exp_month, exp_year, last4, brand, type },
      { withCredentials: true, supressErrorCodeExpectation: true },
      authToken
    );

    const { client_secret: clientSecret } = data;

    const { paymentIntent, error: confirmError } = yield stripe.confirmCardPayment(
      clientSecret,
      { payment_method: e.paymentMethod.id },
      { handleActions: false }
    );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      e.complete('fail');
      yield put({ type: 'SUBMIT_ALT_PAYMENT_FAILED', message: e.message });
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      e.complete('success');
      navigate(paths.ORDER_CONFIRMATION + `/${data.orderNumber}`);

      const urlDiscount = cart.urlDiscount;
      const shouldClearUrlDiscount = urlDiscount && Object.keys(data.discounts)?.includes?.(urlDiscount);
      const now = new Date();
      now.setHours(0, 0, 0, 0);

      postOrderCleanup({ order: { ...data, eventPayMethod: altPayType }, cart, isLoggedIn, capiData, customer });
      yield put(clearCart({ urlDiscount: shouldClearUrlDiscount ? null : urlDiscount }));
      yield put(resetReservation());
      if (isLoggedIn) {
        yield put(fetchMyFarm());
        const hasFarmstandItems = !!data.items?.filter?.((item) => item.category === shopCategories.FARMSTAND)?.length;
        if (hasFarmstandItems) yield put(fetchUserDevices());
      }

      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === 'requires_action') {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = yield stripe.confirmCardPayment(clientSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
          yield put({ type: 'SUBMIT_ALT_PAYMENT_SUCCEEDED' });
        }
      } else {
        // The payment has succeeded.
        yield put({ type: 'SUBMIT_ALT_PAYMENT_SUCCEEDED' });
      }
    }
  } catch (err) {
    window.dataLayer?.push({
      event: 'formSubmitFailure',
      formId: 'checkout',
    });
    logError(err);
    e.complete('fail');
    yield put({ type: 'SUBMIT_ALT_PAYMENT_FAILED', message: e.message });
  }
}

function* submitAltPaymentSaga() {
  yield takeLatest('SUBMIT_ALT_PAYMENT', submitAltPayment);
}

function* guestAuthTokenSaga() {
  yield takeLatest('CHECKOUT_AUTH_TOKEN', guestAuthToken);
}

function* logoutSaga() {
  yield takeLatest(userLogout.toString(), doLogout);
}

function* checkAccountSaga() {
  yield takeLatest('CHECK_ACCOUNT', checkAccount);
}

function* checkDuplicatedOrderSaga() {
  yield takeLatest(checkDuplicatedOrderAction.toString(), checkDuplicatedOrder);
}

export default [submitAltPaymentSaga, guestAuthTokenSaga, logoutSaga, checkAccountSaga, checkDuplicatedOrderSaga];
