import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import CustomerSlide from './slider-content/CustomerSlide';
import { CustomerReviewSliderStyled } from './CustomerReviewSlider.styled';
import { Swiper } from 'components';

// eslint-disable-next-line no-unused-vars, react/prop-types
const SlickButton = ({ currentSlide, slideCount, children, ...props }) => <div {...props}>{children}</div>;

const sliderConfig = {
  dots: false,
  infinite: true,
  speed: 1000,
  navigation: true,
  slidesPerView: 4,
  slidesToScroll: 1,
  initialSlide: 4,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 1,
    },
    740: {
      slidesPerView: 2,
    },
    1440: {
      slidesPerView: 4,
      navigation: false,
    },
  },
};

/**
 * We shoul keep backgroundA, backgroundB props together with new backgroundAGatsby, backgroundBGatsby
 * to support both <Image> and <GatsbyImage> components, since this component is still being used
 * on some non-static pages that can't use <GatsbyImage>
 */
const CustomerReviewSlider = ({ customerReviews = [], backgroundA, backgroundB }) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "5iKB67LikQEZLgZsUyhQQb" }) {
        leftBackgroundAccent {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
        rightBackgroundAccent {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;
  const getVariant = (idx) => {
    let mod = '';
    if (idx % 4 === 1) mod = 'imageBackground';
    if (idx % 4 === 2) mod = 'darkBackground';
    if (idx % 4 === 3) mod = 'imageBackground';
    return mod;
  };
  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        const backgroundAGatsby = data?.contentfulSectionBlocks?.leftBackgroundAccent?.gatsbyImageData;
        const backgroundBGatsby = data?.contentfulSectionBlocks?.rightBackgroundAccent?.gatsbyImageData;
        const getReview = (review, idx) => {
          const modifier = getVariant(idx);
          return (
            <CustomerSlide
              key={review?.sys?.id}
              modifiers={modifier}
              backgroundImage={idx % 4 === 1 ? backgroundA : backgroundB}
              backgroundImageGatsby={idx % 4 === 1 ? backgroundAGatsby : backgroundBGatsby}
              review={review?.fields?.body}
              customerName={review?.fields?.title}
              reviewLocation={review?.fields?.subTitle}
            />
          );
        };
        return (
          <CustomerReviewSliderStyled>
            <Swiper items={customerReviews} swiperProps={sliderConfig} renderSlideCard={getReview} />
          </CustomerReviewSliderStyled>
        );
      }}
    ></StaticQuery>
  );
};

CustomerReviewSlider.propTypes = {
  customerReviews: PropTypes.array,
  backgroundA: PropTypes.string,
  backgroundB: PropTypes.string,
  backgroundAGatsby: PropTypes.object,
  backgroundBGatsby: PropTypes.object,
};

export default CustomerReviewSlider;
